import React from 'react';
import { landingP1 } from '../img';

const Xizmatlarimiz = () => {
  return (
    <>
      <div className='bg-[#EAFFFC]' id="xizmatlarimiz">
        <div className='flex flex-col lg:flex-row justify-between container mx-auto lg:pt-0'>
          <div className='lg:w-[40%] m-5 lg:m-10 lg:px-10 pt-5 sm:pt-10 text-[#174F71]'>
            <h1 className='text-3xl sm:text-4xl lg:text-6xl leading-tight font-[Gilroy-black] text-[30px] lg:text-[90px]'>
            Web - Sayt va mobil-ilovalar Yaratish
            </h1>
            <p className='py-3 lg:py-5 font-[Gilroy-regular]'>
              Jamomiz murakkab, kichik va har hil turdagi veb-saytlarni boshidan ishlab chiqqan holda tez va sifatli ko’rinishda mijozga taqdim etadi.
            </p>
            <button>
              <a href="#portfolio" className='btn rounded-full font-[Gilroy-regular] text-sm lg:text-base px-14 py-2 text-white'>
                Portfolio
              </a>
            </button>
          </div>
          <div className='w-full lg:w-[60%] p-5 lg:p-10'>
            <img src={landingP1} alt='' className='w-full' />
          </div>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row justify-between lg:items-center mx-4 md:mx-16 lg:mx-16 my-10'>
        <div className='mb-5 lg:mb-0'>
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11" viewBox="0 0 60 11" fill="none" className='mb-2'>
  <path d="M0 5.5C0 2.46243 2.46243 0 5.5 0H54.5C57.5376 0 60 2.46243 60 5.5C60 8.53757 57.5376 11 54.5 11H5.5C2.46243 11 0 8.53757 0 5.5Z" fill="url(#paint0_linear_163_512)"/>
  <defs>
    <linearGradient id="paint0_linear_163_512" x1="-2.2973" y1="4.02085e-06" x2="59.9717" y2="11.1577" gradientUnits="userSpaceOnUse">
      <stop stop-color="#174F71"/>
      <stop offset="1" stop-color="#64BAB5"/>
    </linearGradient>
  </defs>
</svg>
          <h1 className='text-3xl md:text-5xl lg:text-5xl leading-tight font-[Gilroy-black] text-[#174F71]'>Web-sayt va <br />Mobil-ilova turlari</h1>
        </div>
        <div className='flex flex-col md:flex-row lg:flex-row font-[Gilroy-regular] font-semibold text-[#174F71]'>
          <div className=''>
            <div className='border-b-2 border-t-2 md:border-t-0 lg:border-r-2 md:border-r-2 p-2 md:p-10 lg:p-5'>
              <h1 className='text-2xl '>
                sayt-vizitka
              </h1>
            </div>
            <div className='border-b-2 md:border-b-0 lg:border-r-2 md:border-r-2 p-2 md:p-10 lg:p-5'>
              <h1 className='text-2xl '>
                lending-sahifa
              </h1>
            </div>
          </div>
          <div className=''>
            <div className='border-b-2 p-2 md:p-10 lg:p-5'>
              <h1 className='text-2xl '>
                internet-magazin
              </h1>
            </div>
            <div className='p-2 border-b-2 md:border-b-0 md:p-10 lg:p-5'>
              <h1 className='text-2xl'>
               taksi-buyurtma
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Xizmatlarimiz;
