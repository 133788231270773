import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import {
  portfolio1,
  portfolio2,
  portfolio3,
  portfolio4,
  portfolio5,
  portfolio6,
  portfolio7,
  portfolio8,
  portfolio9,
} from '../img';

const SliderP = () => {
  const slider = React.useRef(null);
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Adjust the breakpoint for tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  // Define an array of links corresponding to each image
  const imageLinks = [
    'https://mc.uz/oz',
    'https://link.upress.uz/',
    'https://savdomarket.uz/ru',
    'https://teploik.uz/',
    'https://intertaminot.uz/',
    'https://hfez.uz/',
    'https://www.tourscentralasia.com/',
    'https://play.google.com/store/apps/details?id=uz.astrontest',
    'https://intertaminot.uz/',
  ];

  return (
    <div className="mx-6 sm:mx-16 md:mx-24">
      <div className="m-2">
        <button onClick={() => slider?.current?.slickPrev()} className="btn ml-3 px-5 py-1 rounded-full text-white">
          Prev
        </button>
        <button className="btn ml-3 px-5 py-1 rounded-full text-white" onClick={() => slider?.current?.slickNext()}>
          Next
        </button>
      </div>

      <div className='slider-p-container'>
      <Slider ref={slider} {...settings}>
        {[portfolio1, portfolio2, portfolio3, portfolio4, portfolio5, portfolio6, portfolio7, portfolio8, portfolio9].map(
          (portfolio, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4 relative"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <a href={imageLinks[index]} target="_blank" rel="noopener noreferrer" className="image-link">
                <img
                  src={portfolio}
                  alt={`Portfolio ${index + 1}`}
                  className="hover:blur-sm transition duration-700 ease-in-out"
                />
                {hoveredIndex === index && (
                  <div className="link-container">
                    <a href={imageLinks[index]} target="_blank" rel="noopener noreferrer" className="btn text-white py-3 px-10 rounded-full">
                      Ko'rish
                    </a>
                  </div>
                )}
              </a>
            </div>

          )
        )}
      </Slider>
      </div>
    </div>
  );
};

export default SliderP;
