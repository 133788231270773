import React from 'react';

const Footer = () => {
  return (
    <footer className='my-[10rem] border-t-2' id='boglanish'>
      <div className='container mx-auto px-4 sm:px-6 lg:px-8'>
        <h1 className='text-center text-[50px] font-semibold mb-6 text-[#174F71] font-[Gilroy-black]'>
          BIZNESINGIZ UCHUN LOYIHANI MUHOKAMA QILISH UCHUN BIZGA MUROJAAT QILING;
        </h1>
        <div className='flex flex-col sm:flex-row mx-16 justify-between'>
          <div className='mb-4 sm:mb-0 font-[Gilroy-regular]'>
            <p className='font-medium text-[20px] text-[#789bad]'>
              +998 91 911 11 11<br />
              zamaxshariy@mail.ru
            </p>
            <p className='mt-2 font-medium text-[20px] text-[#789bad]'>
              O’zbekison, Toshkent sh,<br />
              massiv Amir Temur, 223
            </p>
          </div>
          <form className='mt-4 sm:mt-0 sm:w-96'>
            <input
              type='text'
              placeholder='Ismingizni kiriting'
              className='w-full px-4 py-2 rounded-full bg-white text-[#7D8DFF] mb-3'
            />
            <input
              type='tel'
              placeholder='+998 99 999 99 99'
              className='w-full px-4 py-2 rounded-full mb-3'
            />
            <button
              type='submit'
              className='font-[Gilroy-regular] font-semibold text-white btn py-2 px-10 rounded-full'
            >
              Ma’lumot Yuborish
            </button>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
