import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Portfolio from './components/Portfolio';
import Xizmatlarimiz from './components/Xizmatlarimiz';
import BizHaqimizda from './components/BizHaqimizda';
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Navbar  />
    <Hero />
    <Portfolio />
    <Xizmatlarimiz />
    <BizHaqimizda />
    <Footer />
    </>
  );
}

export default App;
