import React from "react";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import SliderP from "./SliderP";
import "./index.css";

const Portfolio = () => {
  return (
    <div>
      <div className='bg-[#EAFFFC]' id='portfolio'>
        <div className='mx-6 md:mx-16 lg:mx-16 my-6 sm:my-10 md:my-16 pt-6 sm:pt-10 md:pt-20'>
          <svg xmlns='http://www.w3.org/2000/svg' width='60' height='11' viewBox='0 0 60 11' fill='none' className="mb-2">
            <path d='M0 5.5C0 2.46243 2.46243 0 5.5 0H54.5C57.5376 0 60 2.46243 60 5.5C60 8.53757 57.5376 11 54.5 11H5.5C2.46243 11 0 8.53757 0 5.5Z' fill='url(#paint0_linear_161_75)' />
            <defs>
              <linearGradient id='paint0_linear_161_75' x1='-2.2973' y1='4.02085e-06' x2='59.9717' y2='11.1577' gradientUnits='userSpaceOnUse'>
                <stop stop-color='#7686FF' />
                <stop offset='1' stop-color='#96A2FF' />
              </linearGradient>
            </defs>
          </svg>
          <h1 className='text-3xl md:text-5xl lg:text-5xl leading-tight font-[Gilroy-black] text-[#1D5676]'>
            Portfolio
          </h1>
        </div>

        <SliderP />
      </div>

      <div className='mx-6 md:mx-16 lg:mx-16 my-6 sm:my-10 md:my-16 pt-6 sm:pt-10 md:pt-20'>
        <svg xmlns='http://www.w3.org/2000/svg' width='60' height='11' viewBox='0 0 60 11' fill='none' className="mb-2">
          <path d='M0 5.5C0 2.46243 2.46243 0 5.5 0H54.5C57.5376 0 60 2.46243 60 5.5C60 8.53757 57.5376 11 54.5 11H5.5C2.46243 11 0 8.53757 0 5.5Z' fill='url(#paint0_linear_161_75)' />
          <defs>
            <linearGradient id='paint0_linear_161_75' x1='-2.2973' y1='4.02085e-06' x2='59.9717' y2='11.1577' gradientUnits='userSpaceOnUse'>
              <stop stop-color='#7686FF' />
              <stop offset='1' stop-color='#96A2FF' />
            </linearGradient>
          </defs>
        </svg>
        <h1 className='text-3xl md:text-5xl lg:text-5xl leading-tight font-[Gilroy-black] text-[#1D5676]'>
          Bizning professional jamoamizning loyihalari va zamonaviy dizayndaki ishlari.
        </h1>
      </div>
    </div>
  );
};

export default Portfolio;
