import React from 'react';
import { heroImage } from '../img';

const Hero = () => {
  return (
    <div className=''>
      <div className='bg-[#EAFFFC]' id='hero'>
        <div className='flex flex-col lg:flex-row justify-between container mx-auto lg:pt-0'>
          <div className='lg:w-[40%] m-5 lg:px-10 pt-5 sm:pt-10 text-[#12486C]'>
            <h1 className='text-3xl sm:text-4xl lg:text-6xl leading-tight font-[Gilroy-black] lg:pt-20'>
              Biznesingizni Biz Bilan Yuksaltiring.
            </h1>
            <p className='py-3 lg:py-5 font-[Gilroy-regular]'>
              Internet Marketing, Google Reklama va SEO Agentligi. Biznesingiz uchun internet marketing xizmatlari kerakmi? Buni faqat o’z ishining ustalariga topshiring!
            </p>
            <button>
              <a href="#bizhaqimizda" className='btn rounded-full font-[Gilroy-regular] text-sm lg:text-base px-5 py-2 text-white'>
                Batafsil ma'lumot
              </a>
            </button>
          </div>
          <div className='w-full lg:w-[60%] p-5 lg:p-10'>
            <img src={heroImage} alt='' className='w-full' />
          </div>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row justify-between lg:items-center mx-4 md:mx-16 lg:mx-16 my-10'>
        <div className='mb-5 lg:mb-0'>
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11" viewBox="0 0 60 11" fill="none" className='mb-2'>
  <path d="M0 5.5C0 2.46243 2.46243 0 5.5 0H54.5C57.5376 0 60 2.46243 60 5.5C60 8.53757 57.5376 11 54.5 11H5.5C2.46243 11 0 8.53757 0 5.5Z" fill="url(#paint0_linear_161_75)"/>
  <defs>
    <linearGradient id="paint0_linear_161_75" x1="-2.2973" y1="4.02085e-06" x2="59.9717" y2="11.1577" gradientUnits="userSpaceOnUse">
      <stop stop-color="#185071"/>
      <stop offset="1" stop-color="#74D1C3"/>
    </linearGradient>
  </defs>
</svg>
          <h1 className='text-3xl md:text-5xl lg:text-5xl leading-tight font-[Gilroy-black] text-[#164D70]'>Oxirgi yildagi <br /> yutuqlarimiz:</h1>
        </div>
        <div className='flex flex-col md:flex-row lg:flex-row font-[Gilroy-black]'>
          <div className=''>
            <div className='border-b-2 border-t-2 md:border-t-0 lg:border-r-2 md:border-r-2 p-2 md:p-10 lg:p-2'>
              <h1 className='text-2xl lg:text-3xl text-[#175071]'>
                <span className='text-2xl md:text-4xl lg:text-5xl text-[#76D3C4]'>31 ta</span> kompaniya
              </h1>
              <p className='font-[Gilroy-regular] font-semibold text-[#154C6F]'>doimiy mijozimizga aylandi</p>
            </div>
            <div className='border-b-2 md:border-b-0 lg:border-r-2 md:border-r-2 p-2 md:p-10 lg:p-2'>
              <h1 className='text-2xl lg:text-3xl text-[#175071]'>
                <span className='text-2xl md:text-4xl lg:text-5xl text-[#76D3C4]'>43 ta</span> veb-sayt
              </h1>
              <p className='font-[Gilroy-regular] font-semibold text-[#154C6F]'>muvofiqiatli mijozlarga topshirildi</p>
            </div>
          </div>
          <div className=''>
            <div className='border-b-2 p-2 md:p-10 lg:p-2'>
              <h1 className='text-2xl lg:text-3xl text-[#175071]'>
                <span className='text-2xl md:text-4xl lg:text-5xl text-[#76D3C4]'>14 ta</span> mobil-ilova
              </h1>
              <p className='font-[Gilroy-regular] font-semibold text-[#154C6F]'>ishga tushirildi</p>
            </div>
            <div className='p-2 border-b-2 md:border-b-0 md:p-10 lg:p-2'>
              <h1 className='text-2xl lg:text-3xl text-[#175071]'>
                <span className='text-2xl md:text-4xl lg:text-5xl text-[#76D3C4]'>5 ta</span> crm
              </h1>
              <p className='font-[Gilroy-regular] font-semibold text-[#154C6F]'>bilan shug’ulandik</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;